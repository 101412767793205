import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import ipad from '../assets/images/ipad.png';
import demoImage1 from '../assets/images/sketch-gray.jpg';
import demoImage2 from '../assets/images/demo-image-02.jpg';
import bgMaster from '../assets/images/bg-masthead.png';
import bgMaster2 from '../assets/images/tcc-gray.jpg';
import demoImage3 from '../assets/images/jatin-gray.jpg';
import demoImage4 from '../assets/images/music-img.jpeg';




const IndexPage = () => (
  <Layout>
    {/* <Header /> */}


    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">

          {/* <h1 style={{"marginTop":"0rem"}} className="mx-auto my-0 text-uppercase">The Talk Show Talk Show </h1> */}

          {/* <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2> */}
          <Scroll type="id" element="about">

          </Scroll>
        </div>
      </div>
    </header>

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h1 className="text-white mb-4">Talk Show Talk Show</h1>
            <p className="text-white-50">
              at Lahe Lahe
            </p>
            <p className="text-white-50">
              The Talk Show Talk Show at Lahe Lahe is a late night talk show that comprises of stand-up comedy, sketches, interviews and music. The latest edition is taking place on 7th March 2020 at Lahe Lahe, Indiranagar.
            </p>
          </div>
        </div>
        {/* <img src={ipad} className="img-fluid" alt="" /> */}
      </div>
    </section>

    <section id="projects" className="projects-section bg-light">
      <div className="container" style={{"margin":"auto"}}>
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-8 col-lg-7">
            <img className="img-fluid mb-3 mb-lg-0" src={bgMaster2} alt="" />
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4>Devashish Mulye</h4>
              <p className="text-black-50 mb-0">
                Devashish Mulye has been doing stand-up comedy for 5 years. He is also a filmmaker, writer and an astronaut. He is a man of many talents, including finding chiral carbons and performing the titration of HCl with NaOH.
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage1} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">Bangalore Sketchy Nights</h4>
                  <p className="mb-0 text-white-50">
                    The comedy troupe Bangalore Sketchy Nights will be doing their sketches that could make even a recently berieved Nazi laugh.
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage4} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <h4 className="text-white">Music</h4>
                  <p className="mb-0 text-white-50">
                    Human beings like Music. Goury Prathap is a human being who plays music. What a perfect match. She has played in legendary festivals like Coachella and Tomorrowland. She was the lead guitarist of the band Guns n Roses and has been shredding on the guitar since the age of 0.
                  </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
          
          
          <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage3} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">Jatin Pandey</h4>
                  <p className="mb-0 text-white-50">
                    Jatin Pandey is a stand-up comedian, jokester, and an all-round fine guy. He believes in living in the moment and hiding his true intentions from others.
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>

        </div>
        </div>
      </div>
    </section>

    <Subscribe />

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
