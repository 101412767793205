import React from 'react';

export default function Subscribe() {
  return (
    <section id="signup" className="signup-section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">

            <form className="form-inline d-flex" action="https://www.eventshigh.com/detail/Bangalore/d6fbcfb077cd011191eccd9ed94bd57b" method="get" target="_blank">
    
              <button type="Book a spot" className="btn btn-primary mx-auto">
                Book a Spot
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
